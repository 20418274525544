import SockJS from "sockjs-client";
import Stomp, { Client } from "webstomp-client";
import { TYPE } from "vue-toastification";
import LeaderLine from "leader-line";
import { apiCall, apiCallParams, logResponse } from "@/core/api";
import { getParameter } from "@/core/util";
import { showAlert } from "@/core/popup";
import { sortedByIxAndIy } from "@/flow/domain/flow";
import REQUEST_TYPE from "@/core/api/request-types";
import i18n from "@/core/i18n/i18n";

const { t } = i18n.global;

export async function getFlowList(): Promise<any> {
  const endpoint = "/flows";
  const response = await apiCall("GET", endpoint);
  if (response.code === 200) {
    return response.result;
  }
  logResponse(response);
  return undefined;
}

export async function exportFlow(exportDto: any): Promise<any> {
  const endpoint = "/flows/export";
  const response = await apiCall("POST", endpoint, exportDto);
  if (response.code === 200) {
    return response.message;
  }
  logResponse(response);
  return false;
}
export async function getSystemVariables(): Promise<any> {
  const endpoint = "/system-variables/keys";
  const response = await apiCall("GET", endpoint);
  if (response.code === 200) {
    return response.result;
  }
  logResponse(response);
  return undefined;
}

export async function getExpressionDetails(): Promise<any> {
  const endpoint = "/expressions/details";
  const response = await apiCall("GET", endpoint);
  if (response.code === 200) {
    return response.result;
  }
  logResponse(response);
  return undefined;
}
export async function getConfigurationFramework(flowId: string): Promise<any> {
  const endpoint = `/configs/Root/FLOW/${flowId}`;
  const response = await apiCall("GET", endpoint);
  if (response.code === 200) {
    return response.result;
  }
  logResponse(response);
  return undefined;
}
export async function updateConfigurationFramework(data: any): Promise<any> {
  const endpoint = "/configs";
  const requestBody = {
    key: data.label,
    level: data.level,
    value: data.inputValue,
    type: data.type,
    children: data.children || [],
    ownerId: data.id,
  };

  try {
    const response = await apiCall("PUT", endpoint, requestBody);
    if (response.code === 200) {
      console.log("Configuration updated successfully");
    } else {
      logResponse(response);
    }
  } catch (error) {
    console.error("API Error:", error);
  }
}
export async function verifyConnectors(flowId: string, organizationId: string): Promise<any> {
  const endpoint = "/flows/verify";
  const response = await apiCallParams("GET", endpoint, {
    flowId,
    organizationId,
  });
  if (response.code === 200) {
    return {
      verified: true,
    };
  }
  if (response.code === 417) {
    return {
      verified: false,
      list: response.result,
    };
  }
  logResponse(response);
  return false;
}

export async function getFilteredFlowList(params: any): Promise<any> {
  const endpoint = "/flows/listing";
  const response = await apiCallParams("GET", endpoint, params);
  if (response.code === 200) {
    return response.page;
  }
  logResponse(response);
  return undefined;
}

export async function getActiveFlowList(): Promise<any> {
  const endpoint = "/flows/active";
  const response = await apiCall("GET", endpoint);
  if (response.code === 200) {
    return response.result;
  }
  logResponse(response);
  return undefined;
}

export async function getFlow(flowId: string, version: string): Promise<any> {
  const endpoint = `/flows/${flowId}/${version}`;
  const response = await apiCall("GET", endpoint);
  if (response.code === 200) {
    return sortedByIxAndIy(response.result[0]);
  }
  logResponse(response);
  return undefined;
}

export async function startFlow(flowId: string, version: string): Promise<any> {
  const endpoint = `/workflows/${flowId}/${version}/start`;
  const response = await apiCall("POST", endpoint);
  if (response.code === 200) {
    return response.result[0];
  }
  logResponse(response);
  return undefined;
}

export async function stopFlow(activityId: string): Promise<boolean> {
  const endpoint = `/workflows/activity/${activityId}/stop`;
  const response = await apiCall("POST", endpoint);
  if (response.code === 200) {
    return true;
  }
  logResponse(response);
  return false;
}

export async function restartFlow(activityId: string): Promise<boolean> {
  const endpoint = `/workflows/activity/${activityId}/start`;
  const response = await apiCall("POST", endpoint);
  if (response.code === 200) {
    return true;
  }
  logResponse(response);
  return false;
}

export async function deleteFlow(flowId: string, version: string): Promise<boolean> {
  const endpoint = `/flows/${flowId}/${version}`;
  const response = await apiCall("DELETE", endpoint);
  if (response.code === 200) {
    return true;
  }
  logResponse(response);
  return false;
}

export async function inLineEditFlow(flowId: string, flow: any): Promise<any> {
  const endpoint = `/flows/edit/${flowId}`;
  const response = await apiCall("POST", endpoint, flow);
  if (response.code === 200) {
    if (response.result?.code === "1011") {
      showAlert(TYPE.WARNING, "Flow with the same name exists.");
      return undefined;
    }
    return true;
  }
  logResponse(response);
  return undefined;
}

export async function getConnections(): Promise<any> {
  const endpoint = "/connections/organization";
  const response = await apiCall("GET", endpoint);
  if (response.code === 200) {
    return response.result;
  }
  logResponse(response);
  return undefined;
}

export async function getAllActions(connectorSpecId: string): Promise<any> {
  const endpoint = `/connectorui/${connectorSpecId}`;
  const response = await apiCall("GET", endpoint, undefined, REQUEST_TYPE.CONNECTOR);
  if (response.code === 200) {
    return response.result.actions;
  }
  logResponse(response);
  return undefined;
}

export async function getConsumerActions(connectorSpecId: string): Promise<any> {
  const actions = await getAllActions(connectorSpecId);
  return actions?.filter((action) => action.actionSpec.actionType === "CONSUMER");
}

export async function getProducerActions(connectorSpecId: string): Promise<any> {
  const actions = await getAllActions(connectorSpecId);
  return actions?.filter((action) => action.actionSpec.actionType === "PRODUCER");
}

export async function addFlow(flow: Record<string, unknown>): Promise<any> {
  const endpoint = "/flows/add";
  const response = await apiCall("POST", endpoint, flow);
  if (response.code === 200) {
    if (response.result?.code === "1011") {
      showAlert(TYPE.WARNING, "Flow with the same name exists.");
      return undefined;
    }
    return response.result[0];
  }
  logResponse(response);
  return undefined;
}

export async function editFlow(flow: Record<string, any>): Promise<any> {
  const endpoint = "/flows";
  const response = await apiCall("PUT", endpoint, flow);
  if (response.code === 200) {
    if (response.result?.code === "400") {
      showAlert(TYPE.WARNING, response.message);
      return undefined;
    }
    if (response.result?.code === "1012") {
      showAlert(TYPE.WARNING, response.message);
      return undefined;
    }
    return response.result[0];
  }
  logResponse(response);
  return undefined;
}

export async function simulateStep(stepId: string, flowId: string): Promise<any> {
  const endpoint = `/workflows/${stepId}/${flowId}/simulate`;
  const response = await apiCall("POST", endpoint);
  if (response.code === 200) {
    return response.result[0];
  }
  logResponse(response);
  return undefined;
}

export async function getStep(stepId: string): Promise<any> {
  const endpoint = `/steps/${stepId}`;
  const response = await apiCall("GET", endpoint);
  if (response.code === 200) {
    return response.result[0];
  }
  logResponse(response);
  return undefined;
}

export async function getActiveFlowCount(): Promise<any> {
  const endpoint = "/flows/organization";
  const response = await apiCall("GET", endpoint);
  if (response.code === 200) {
    return response.result[0];
  }
  logResponse(response);
  return undefined;
}

export async function getRunningFlowsCount(): Promise<any> {
  const endpoint = "/flows/running";
  const response = await apiCall("GET", endpoint);
  if (response.code === 200) {
    return response.result[0];
  }
  logResponse(response);
  return undefined;
}

export async function connectWebsocket(): Promise<Client> {
  const websocketUrl = await getParameter("WEBSOCKET_URL");
  const socket = new SockJS(websocketUrl);
  return Stomp.over(socket);
}

export function isRunning(flowStatusType: string): boolean {
  switch (flowStatusType) {
    case "STARTED":
    case "STARTING":
    case "TRIGGERED":
    case "INPROGRESS":
    case "RETRYING":
      return true;
    case "PUBLISHED":
    case "STOPPED":
    case "ABORTED":
    case "FAILED":
    case "COMPLETED":
      return false;
    default:
      return false;
  }
}

export function isFailed(flowStatusType: string): boolean {
  return flowStatusType === "FAILED";
}

export async function getElasticLogs(flowId: string, flowVersion: number): Promise<any> {
  const endpoint = `/flow_logs/latest/${flowId}/${flowVersion}`;
  const response = await apiCall("GET", endpoint);
  if (response.code === 200) {
    return response.result;
  }
  return undefined;
}

export async function getLatestLogs(flowId: string): Promise<any> {
  const endpoint = `/flow_logs/latest/${flowId}`;
  const response = await apiCall("GET", endpoint, flowId);
  if (response.code === 200) {
    return response.result;
  }
  return undefined;
}

export async function getExchangeLog(activityId: string): Promise<any> {
  const endpoint = `/exchange_logs/activity/${activityId}`;
  const response = await apiCall("GET", endpoint, activityId);
  if (response.code === 200) {
    return response.result;
  }
  return undefined;
}

export async function exportFlowJson(flowId: string): Promise<any> {
  const endpoint = `/flows/export/${flowId}`;
  const response = await apiCall("GET", endpoint, flowId);
  if (response.code === 200) {
    const text = JSON.stringify(response.result);
    const url = URL.createObjectURL(new Blob([text], { type: "text/plain" }));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "file.json");
    document.body.appendChild(link);
    link.click();
  }
  logResponse(response);
  return undefined;
}

export async function checkFlowNameExists(organizationId: string, flowName: string): Promise<any> {
  const endpoint = "/flows/name";
  const response = await apiCallParams("GET", endpoint, {
    organizationId,
    flowName,
  });
  if (response.code === 200) {
    return "NOT_EXIST";
  }
  if (response.code === 417) {
    return "EXIST";
  }
  logResponse(response);
  return "ERROR";
}

export async function cloneFlow(flowId: string, flowName: string): Promise<any> {
  const endpoint = "/flows/clone";
  const date = new Date();
  const response = await apiCallParams("POST", endpoint, {
    flowId,
    flowName: `${flowName}_CLONE_${date.toDateString()} ${date.toLocaleTimeString()}`,
  });
  if (response.code === 200) {
    return true;
  }
  logResponse(response);
  return false;
}

export async function getFlowVersions(flowId: string): Promise<any> {
  const endpoint = `/flows/versions/${flowId}`;
  const response = await apiCall("GET", endpoint);
  if (response.code === 200) {
    return response.result;
  }
  logResponse(response);
  return undefined;
}

export async function revertFlow(flowId: string, version: any): Promise<any> {
  const endpoint = "/flows/revert";

  const response = await apiCallParams("POST", endpoint, {
    flowId,
    version,
  });

  if (response.code === 200) {
    return response.result[0];
  }

  logResponse(response);
  return undefined;
}

export function convertDate(dateStr?: string): string {
  if (dateStr) {
    const milliseconds = Date.parse(dateStr);
    const date = new Date(milliseconds);
    return date.toLocaleString();
  }
  return "";
}
export async function getModifiedUser(modifiedById: string): Promise<any> {
  const endpoint = `/users/get/${modifiedById}`;
  const response = await apiCall("GET", endpoint, modifiedById);
  if (response.code === 200) {
    const name = response.result[0]?.name || "";
    const lastname = response.result[0]?.lastname || "";
    return `${name} ${lastname}`;
  }
  return "";
}

export async function getFlowActiveValue(flowId: string, version: string): Promise<any> {
  const endpoint = `/flows/active/${flowId}/${version}`;
  const response = await apiCall("GET", endpoint);
  if (response.code === 200) {
    return response.result;
  }
  logResponse(response);
  return undefined;
}

export function statusColor(flowStatusType: any): string {
  switch (flowStatusType) {
    case "STARTED":
    case "STARTING":
    case "TRIGGERED":
    case "PROGRESS":
    case "RETRYING":
      return "success";
    case "ABORTED":
    case "FAILED":
      return "danger";
    case "STOPPED":
      return "warning";
    case "COMPLETED":
    case "PUBLISHED":
      return "";
    default:
      return "";
  }
}

export interface Flow {
  name?: string,
  description?: string,
  port?: number,
  pid?: number,
  version?: number,
  latest?: boolean,
  flowStatusType?: string,
  scheduleType?: string,
  schedule?: {
    cronExpr: string,
    scheduleTime: string,
    nextFireTime: string,
    timeZone: string,
    settings: Record<string, unknown>
  },
  activities?: [],
  trigger?: any,
  steps?: [],
  webhookPayloads?: [],
  active?: boolean,
  createdAt?: string,
  updatedAt?: string,
  timeZone?: string,
  createdBy?: any,
  modifiedBy?: any,
  createdOrgId?: any,
  continueOnFailure?: boolean
  routerSelected?: boolean,
  userDefinedVariables?: Record<string, string>
}

export interface LoopItem {
  startIdx: number,
  endIdx: number,
}

export interface Route {
  prevStepIndex?: number,
  parentRouteIndex?: number,
  conditionalRoutes: Array<ConditionalRoutes>,
}

export interface ConditionalRoutes {
  condition?: any,
  steps?: any[]
}

export function createLine(start: any, end: any, lineParams: any, containerName: string, wrapped = false): LeaderLine {
  LeaderLine.positionByWindowResize = false;
  // if wrapped, skip elementInViewport check
  if ((start && end)
      && (wrapped || (!wrapped && elementInViewport(start, containerName) && elementInViewport(end, containerName)))) {
    return new LeaderLine(LeaderLine.pointAnchor(start, {
      x: end.clientLeft + lineParams.startLeft,
      y: end.clientTop + lineParams.startTop,
    }),
    LeaderLine.pointAnchor(end, {
      x: end.clientLeft + lineParams.endLeft,
      y: end.clientTop + lineParams.endTop,
    }), {
      color: lineParams.color,
      startPlugColor: lineParams.startPlugColor,
      endPlugColor: lineParams.endPlugColor,
      size: lineParams.size,
      endPlug: "behind",
      startSocket: lineParams.startSocket,
      endSocket: lineParams.endSocket,
      gradient: lineParams.gradient,
      dash: lineParams.dash,
    });
  }
  // workaround for dash square issue on condition dash svg line
  const wrapper = document.getElementById("flow-line-wrapper");
  const svgElement = wrapper.querySelectorAll("use");
  svgElement.forEach((el) => {
    el.style.strokeDashoffset = "2px";
  });
  return null;
}

const intersection = (r1, r2) => {
  const xOverlap = Math.max(0, Math.min(r1.x + r1.w, r2.x + r2.w) - Math.max(r1.x, r2.x));
  const yOverlap = Math.max(0, Math.min(r1.y + r1.h, r2.y + r2.h) - Math.max(r1.y, r2.y));

  return xOverlap * yOverlap;
};

function elementInViewport(element, containerName) {
  const bounding = element.getBoundingClientRect();
  const container = document.getElementById(containerName);
  const dimension = {
    x: bounding.x,
    y: bounding.y,
    w: bounding.width,
    h: bounding.height,
  };
  const viewport = {
    x: container.offsetLeft,
    y: container.offsetTop,
    w: (container.clientWidth || window.innerWidth),
    h: (container.clientHeight || window.innerHeight),
  };
  const divSize = dimension.w * dimension.h;
  const overlap = intersection(dimension, viewport);
  return overlap / divSize > 0.15;
}

export function appendLeaderLines(element: HTMLElement): void {
  const lineElements = document.querySelectorAll(".leader-line");
  lineElements.forEach((lineElement) => {
    if (!lineElement.querySelector("use").getAttribute("style").includes("stroke-width: 4px")) {
      element.appendChild(lineElement);
    }
  });
}

export function removeLeaderLines(element: HTMLElement, callback): void {
  if (element) {
    element.innerHTML = "";
    if (callback) {
      callback();
    }
  }
}

export function setWrapperPosition(element: HTMLElement): void {
  element.style.transform = "none";
  const rectWrapper = element.getBoundingClientRect();
  // Move to the origin of coordinates as the document
  element.style.transform = `translate(${
    // eslint-disable-next-line no-restricted-globals
    -(rectWrapper.left + scrollX)}px, ${
    // eslint-disable-next-line no-restricted-globals
    -(rectWrapper.top + scrollY)}px)`;
}

function checkNextStepTypeIsRouter(list, index) {
  return !!list[index]?.type;
}

function checkNextStepTypeIsStep(list, index) {
  return !list[index]?.type;
}

export function createStepLines(list: Array<any>, listIdx: string, element: HTMLElement, wrapped: boolean, callback): void {
  const stepCount = document.getElementsByClassName("stepBoxCount").length;
  if (!element || element?.childElementCount === stepCount - 1) {
    return;
  }
  const horizontalLineParams = {
    startIdx: 0,
    endIdx: list.length,
    startLeft: 120,
    startTop: 80,
    endLeft: 0,
    endTop: 80,
    clientWidth: 210,
    gradient: true,
    color: undefined,
    startPlugColor: "#e5a1ac",
    endPlugColor: "#5022a6",
    startSocket: "right",
    endSocket: "left",
    size: 6,
    dash: undefined,
  };
  const verticalLineParams = {
    startIdx: 0,
    endIdx: list.length,
    startLeft: 60,
    startTop: 120,
    endLeft: 60,
    endTop: 0,
    clientWidth: 210,
    gradient: true,
    color: undefined,
    startPlugColor: "#e5a1ac",
    endPlugColor: "#5022a6",
    startSocket: "bottom",
    endSocket: "top",
    size: 8,
    dash: {
      len: 8,
      gap: 4,
    },
  };

  list.forEach((step, index) => {
    let endIndex = `${step.uniqueId.toString().split("-").slice(0, -1).join("-")}-${parseInt(step.uniqueId?.toString().split("-").slice(-1)[0], 0) + 1}`;
    if (parseInt(step.uniqueId, 0).toString().length === step.uniqueId.toString().length) {
      endIndex = (parseInt(step.uniqueId, 0) + 1).toString();
    }
    const start = document.getElementById(`step-unique-${step.uniqueId}`) || document.getElementById(`router-unique-${step.uniqueId}`);
    const end = document.getElementById(`step-unique-${endIndex}`) || document.getElementById(`router-unique-${endIndex}`);
    if (start && end) {
      createLine(start, end, horizontalLineParams, "left-container", wrapped);
    }
    if (step.routes && step.routes.length > 0) {
      step.routes.forEach((route, routeIndex) => {
        const startRoute = routeIndex === 0 ? document.getElementById(`router-unique-${step.uniqueId}`) : document.getElementById(`step-unique-${step.uniqueId}-0-0`);
        const endRoute = document.getElementById(`step-unique-${step.uniqueId}-${routeIndex}-0`);
        if (startRoute && endRoute) {
          createLine(startRoute, endRoute, verticalLineParams, "left-container", wrapped);
        }
        createStepLines(route.steps, `${listIdx}-${routeIndex.toString()}`, element, wrapped, null);
      });
    }
  });
  setWrapperPosition(element);
  appendLeaderLines(element);
  if (callback) {
    callback();
  }
}
